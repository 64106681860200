<!--
* @description:
* @fileName activeEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-drawer
    ref="drawerRef"
    v-model="dialogFormVisible"
    :title="title"
    :size="size"
    :before-close="handleClose"
    direction="rtl"
    custom-class="demo-drawer"
  >
    <div class="demo-drawer__content">
      <el-form :model="form" :rules="rules" ref="formRef">
        <el-form-item label="活动标题" prop="title" :label-width="80">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="活动时间" prop="activeTime" :label-width="80">
          <el-date-picker
            v-model="form.activeTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="YYYY-MM-DD HH:mm:ss"
          ></el-date-picker>
        </el-form-item>
        <!-- 
        <el-form-item label="结束时间" prop="endTime" :label-width="80">
          <el-date-picker
            v-model="form.endTime"
            type="datetime"
            placeholder="结束时间"
            value-format="YYYY-MM-DD HH:mm:ss"
          ></el-date-picker>
        </el-form-item> -->
        <el-form-item label="省市选择" prop="areaList" :label-width="80">
          <el-cascader
            :props="optionsLevel"
            v-model="form.areaList"
            style="width: 250px"
          />
        </el-form-item>
        <el-form-item label="活动地址" prop="address" :label-width="80">
          <el-input v-model="form.address" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="海报图片" prop="bannerUrl" :label-width="80">
          <el-upload
            class="upload-demo"
            action="#"
            list-type="picture-card"
            :limit="1"
            :file-list="form.fileList"
            :http-request="upBannar"
            :on-error="handleError"
            :on-exceed="handleExceed"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            accept=".png,.jpeg,.jpg"
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              <em>点击上传文件</em>
            </div>
            <!-- <template #tip>
              <div class="el-upload__tip">
                jpg/png files with a size less than 500kb
              </div>
            </template> -->
          </el-upload>

          <!-- <el-image
            :src="form.bannerUrl"
            :fit="'fill'"
            v-else
            style="width: 360px; height: 180px"
          ></el-image> -->
        </el-form-item>

        <el-form-item label="直播地址" prop="liveUrl" :label-width="80">
          <el-input v-model="form.liveUrl" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="主办方" prop="organisers" :label-width="80">
          <el-input v-model="form.organisers" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="活动人数" prop="peopleNum" :label-width="80">
          <el-input v-model="form.peopleNum" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="活动亮点" prop="sparkle" :label-width="80">
          <el-input v-model="form.sparkle" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="活动标签" :label-width="80">
          <el-select
            v-model="form.activityTagIds"
            placeholder="请选择活动标签"
            multiple
          >
            <template v-for="(item, index) in tagOption" :key="index">
              <el-option :label="item.label" :value="item.data"></el-option>
            </template>
          </el-select>
        </el-form-item>

        <el-form-item label="活动类型" :label-width="80">
          <el-select
            v-model="form.activityTypeIds"
            multiple
            placeholder="请选择活动类型"
          >
            <template v-for="(item, index) in typeOption" :key="index">
              <el-option :label="item.label" :value="item.data"></el-option>
            </template>
          </el-select>
        </el-form-item>

        <el-form-item label="活动状态" :label-width="80">
          <el-radio-group v-model="form.stauts">
            <el-radio :label="'ON'">启用</el-radio>
            <el-radio :label="'OFF'">停用</el-radio>
            <el-radio :label="'DRAFT'">草稿</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="活动简介" :label-width="80">
          <vab-quill
            v-model="form.description"
            :min-height="400"
            :options="Richoptions"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="demo-drawer__footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="submitInfo()">提交</el-button>
    </div>

    <vab-upload
      ref="vabUploadRef"
      :limit="1"
      name="file"
      :size="2"
      url="/upload"
      @addImg="handleAddImg"
    />

    <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
      <div>
        <el-image :src="dialogImageUrl" />
      </div>
    </el-dialog>
  </el-drawer>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import {
    upLoadImg,
    getLabelType,
    getLabelLabel,
    addActive,
    activeDetail,
    editActive,
  } from '@/api/active'

  import { getProvinceInfo, getCityInfo, getAreaInfo } from '@/api/shop'

  import { parseTime } from '@/utils/index'
  import VabQuill from '@/extra/VabQuill'
  import VabUpload from '@/extra/VabUpload'
  export default defineComponent({
    name: 'activeEdit',
    components: {
      VabQuill,
      VabUpload,
    },
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()
      const state = reactive({
        dialogVisible: false,
        dialogImageUrl: '',
        formRef: null,
        vabUploadRef: null,
        checkMenu: [],
        size: '50%',
        form: {
          description: '',
          startTime: '',
          endTime: '',
          areaList: [],
        },
        rules: {
          title: [
            { required: true, trigger: 'blur', message: '请输入活动标题' },
          ],
          activeTime: [
            { required: true, trigger: 'blur', message: '请选择起止时间' },
          ],
          type: [
            { required: true, trigger: 'change', message: '请选择标签类型' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        list: [],
        pickerStartDate: null,
        limit: 1,
        Richoptions: {
          theme: 'snow',
          bounds: document.body,
          debug: 'warn',
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ color: [] }, { background: [] }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ align: [] }],
                [{ direction: 'rtl' }],
                [{ font: [] }],
                ['clean'],
                ['link', 'image'],
              ],
              handlers: {
                image: () => {
                  state['vabUploadRef'].handleShow()
                },
              },
            },
          },
        },
        typeOption: [],
        tagOption: [],
      })

      const showEdit = async (row) => {
        if (!row) {
          state.title = '添加活动'
        } else {
          state.title = '编辑活动'
          row.activeTime = [parseTime(row.startTime), parseTime(row.endTime)]
          row.fileList = [{ name: 'bannar', url: row.bannerUrl }]
          await fetchData()
          row.activityTypeIds = handleData(row.activityTypeId)
          row.activityTagIds = handleData(row.activityTagId)
          row.areaList = [row.province, row.city, row.area]
          state.form = Object.assign({}, row)
        }
        state.dialogFormVisible = true
      }

      const handleData = (arr, checkArr) => {
        for (let i in arr) {
          arr[i] = String(arr[i])
        }
        return arr
      }

      const handleClose = () => {
        state['formRef'].resetFields()
        state.form = {}
        state.form.description = ''
        state.dialogFormVisible = false
      }

      const fetchData = async () => {
        const { data: typeList } = await getLabelType()
        const { data: tagList } = await getLabelLabel({}, 'ACTIVITY')
        state.typeOption = typeList
        state.tagOption = tagList
      }

      // 设置活动开始时间
      const startDateFunc = () => {
        let time = new Date()
        if (state.form.endTime) {
          // time.getTime() 是当前时间，当前时间大于离开时间的禁用掉 / 当前时间小于今天的禁用
          return (
            new Date(state.form.endTime).getTime() < time.getTime() ||
            time.getTime() < Date.now() - 8.64e7
          )
        } else {
          return time.getTime() < Date.now() - 8.64e7
        }
      }

      onMounted(() => {
        fetchData()
        console.log(proxy.startDateFunc())
        state.pickerStartDate = proxy.startDateFunc()
      })
      // 图片相关接口
      // 上传海报
      const upBannar = async (e) => {
        console.log(e)
        let upData = new FormData()
        upData.append('file', e.file)
        const { data } = await upLoadImg(upData)
        console.log(data)
        state.form.bannerUrl = data
      }

      const handleError = (err, file) => {
        proxy.$baseMessage(
          `文件[${file.raw.name}]上传失败,文件大小为${_.round(
            file.raw.size / 1024,
            0
          )}KB`,
          'error',
          'vab-hey-message-error'
        )
      }

      const handleExceed = (files) => {
        proxy.$baseMessage(
          `当前限制选择 ${proxy.limit} 个文件，本次选择了
             ${files.length}
             个文件`,
          'error',
          'vab-hey-message-error'
        )
      }

      const handleRemove = () => {
        state.form.bannerUrl = ''
      }
      const handlePreview = (file) => {
        state.dialogImageUrl = file.url
        state.dialogVisible = true
      }

      // 富文本上传图片
      const handleAddImg = async (e) => {
        state.form.description += `<img src="${e}" />`
      }
      const submitInfo = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            let msg = ''
            console.log(state.form)
            state.form.startTime = state.form.activeTime[0]
            state.form.endTime = state.form.activeTime[1]
            state.form.province = state.form.areaList[0]
            state.form.city = state.form.areaList[1]
            state.form.area = state.form.areaList[2]
            if (state.title == '编辑活动') {
              let data = await editActive(state.form)
              msg = data.msg
            } else {
              let data = await addActive(state.form)
              msg = data.msg
            }
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            handleClose()
          }
        })
      }

      const optionsLevel = {
        lazy: true,
        value: 'name',
        label: 'name',
        async lazyLoad(node, resolve) {
          const { level } = node
          const { code } = node.data
          if (level == 0) {
            const { data } = await getProvinceInfo()
            resolve(data)
          } else if (level == 1) {
            const { data } = await getCityInfo({ code: code })
            resolve(data)
          } else {
            const { data } = await getAreaInfo({ code: code })
            data.forEach((v, i) => {
              v.leaf = level >= 2
            })
            resolve(data)
          }
        },
      }

      return {
        ...toRefs(state),
        showEdit,
        handleClose,
        upBannar,
        fetchData,
        handleAddImg,
        startDateFunc,
        submitInfo,
        handleError,
        handleExceed,
        handleRemove,
        optionsLevel,
        handlePreview,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }

  .demo-drawer__content {
    height: 85vh;
    overflow: auto;
  }
  .demo-drawer__footer {
    position: fixed;
    right: 20px;
    bottom: 10px;
  }
</style>
